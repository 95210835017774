import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Images
import feature1 from "../../assets/images/features/img-1.png";
import feature2 from "../../assets/images/features/img-2.png";
import laptop from "../../assets/images/laptop.png";
import { airData } from '../../datafeed/sitedata';

class Research extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ],
            smallFeatures2 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
        <section className="section" id="research">
            <Container>

       
                <Row>
                    <Col lg={6} sm={12} className="ml-lg-auto">
                        <div >
                    <SectionTitle
                subtitle="Research and Analysis"
                desc={airData.research.subtitle}
            />    </div>
                    </Col>

                    <Col lg={6} sm={12} className="ml-lg-auto">
                    <div className="box-shadow">
                                    <img src={laptop} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
          
</Col>
                </Row>
                
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Research;