import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import bg1 from "../../assets/images/headerImage.jpg";
import { airData } from '../../datafeed/sitedata';

class Jumbo extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="hero-section-5" id="home" style={{ background: `url(${bg1})` }}>
                    {false && <div className="bg-overlay"></div>}
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <div className="hero-wrapper text-center mb-4">
                                    <p className="font-16 text-uppercase text-white-50">{airData.intro}</p>
                                    {false && <h1 className="hero-title text-white mb-4">Make your Site Amazing & Unique with <span className="text-primary">Invoza</span></h1>}
                                    <h1 className="hero-title text-white mb-4">{airData.headline}</h1>
                                    <p className="text-white-50">{airData.subHeadline}</p>

                                    <div className="mt-4">
                                        <Link to="research" className="btn btn-primary mt-2 mr-2">Our Reports</Link>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Jumbo;