import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import Images
import bg1 from "../../assets/images/headerWithText.jpg";
import { airData } from '../../datafeed/sitedata';

class ChartJumbo extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="hero-section-5" id="home" style={{ background: `url(${bg1})`, height: 0,
  backgroundSize: 'cover',
  paddingTop: '50%', backgroundRepeat: 'no-repeat'}}>

                </section>
                {false && 
                <section className="hero-section-5" id="home" style={{ background: `url(${bg1})`,  height: 0,
  backgroundSize: 'contain',
  paddingTop: '50%', backgroundRepeat: 'no-repeat'}}>
                    {false && <div className="bg-overlay"></div>}
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                
                                <div className="hero-wrapper text-center mb-4  alignTop">
                                    <h3 className="hero-title text-white mb-4">{airData.headline}</h3>
                                </div>
    
                            </Col>
                        </Row>
                    </Container>
                </section>}

            </React.Fragment>
        );
    }
}

export default ChartJumbo;