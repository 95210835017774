import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader , Button} from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Images
import feature1 from "../../assets/images/features/img-1.png";
import feature2 from "../../assets/images/features/img-2.png";
import neilglynn from "../../assets/images/extracts/image2-25.png";
import { airData } from '../../datafeed/sitedata';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ],
            smallFeatures2 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
        <section className="section" id="about">
            <Container style={{padding: "0em 3em 0em 3em"}}>


            <SectionTitle
                subtitle="About us"
                
            />   
       
                <Row>
                    <Col className="ml-lg-auto">
                    <p className="mb-0"><span className="font-17">{airData.about.line1bold}</span>{airData.about.line1normal}</p>
                   <p> </p>
                   <p> </p>
                   </Col>
                   </Row>
<Row>
                   <Col  className="ml-lg-auto portrait-pad" >
                    <div className="box-shadow padding-portrait-image">
                                    <img src={neilglynn} alt="neilglynn" className="img-fluid mx-auto d-block"/>
                                </div>
                                </Col>
          </Row>


                   <Row>
                       <Col>
                    <p className="mb-0"><span className="font-12">{airData.about.line2bold}</span>{airData.about.line2normal}</p>
          
                    </Col>
                    </Row>
                    <Row>

                    <Col>
                    
                    <div className="mt-4">
                                         <p className="mb-0"><span className="font-17">For more information or discuss subscription packages:</span></p>
                                         <Button className="btn btn-primary mt-2 mr-2" onClick={() => window.location = 'mailto:neil.glynn@aircontroltower.com'}>Email Neil</Button>
                                   
                                    </div>
                    </Col>
                </Row>
                
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default About;