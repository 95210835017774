import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Service from "../../components/Service/service";
import Features from "../../components/Features/feature";
import Clients from '../../components/Client/client';
import Pricing from "../../components/Pricing/pricing";
import Subscribe from "../../components/Subscribe/Subscribe";
import Footing from "../../components/Footing/footing";
import Testimonials from '../../components/Testimonials/testimonials';
import Research from '../../components/Research/research';
import ReportCardList from '../../components/ReportCard/reportCardList';

class ResearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                { id: 2, idnm: "reportList", navheading: "Reports" },
                { id: 3, idnm: "research", navheading: "Research Overview" },
            ],
            pos: document.documentElement.scrollTop,
            imglight: false,
            navClass: ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "nav-sticky", imglight: false });
        }
        else {
            this.setState({ navClass: "", imglight: false });
        }
    };

    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} imglight={this.state.imglight} />

<ReportCardList />
<Research />
                {/* Importing Footer */}
                <Footing />
            </React.Fragment>
        );
    }
}

export default ResearchPage;