import React, { Component } from 'react';

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from './section';
import Service from "../../components/Service/service";
import Features from "../../components/Features/feature";
import Clients from '../../components/Client/client';
import Pricing from "../../components/Pricing/pricing";
import Subscribe from "../../components/Subscribe/Subscribe";
import Footer from "../../components/Footer/footer";
import Testimonials from '../../components/Testimonials/testimonials';
import { airData } from '../../datafeed/sitedata';
import Jumbo from '../../components/Jumbo/jumbo';
import Research from '../../components/Research/research';
import About from '../../components/About/about';
import ReportCardList from "../../components/ReportCard/reportCardList";
import Footing from '../../components/Footing/footing';
import ChartJumbo from '../../components/ChartJumbo/chartJumbo';
import BigLogo from '../../components/BigLogo/bigLogo';

class Index1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: airData.navItems,
            pos: document.documentElement.scrollTop,
            imglight: false,
            navClass: ""
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "nav-sticky", imglight: false });
        }
        else {
            this.setState({ navClass: "", imglight: false });
        }
    };

    render() {
        return (
            <React.Fragment>

                {/* Importing Navbar */}
                <NavbarPage navItems={this.state.navItems} navClass={this.state.navClass} imglight={this.state.imglight} />



               {false &&  <ChartJumbo />}

                <BigLogo />

                {/* Importing section */}
      

                <About />


                <Clients />


               <Research />

                {/* Importing Clients */}
  



                                {/* Importing Service */}
               {true &&  <Service />}

               <ReportCardList />

                                {/* Importing Features */}
               {false && <Features />}

                {/* Importing Pricing */}
                {false && <Pricing />}
                {/* Importing Sunscribe */}
                {false && <Subscribe />}

                {/* Importing Footer */}
                <Footing />
            </React.Fragment>
        );
    }
}

export default Index1;