import React, { Component } from 'react';


import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    CardLink
} from "reactstrap";

import laptop from "../../assets/images/laptop.png";


class ReportCard extends Component {

    constructor(props) {
        super(props);

    }


    render() {
  return (
<Card
  style={{
   
  }}
>
  <CardBody>
    <CardTitle tag="h5">
      {this.props.report.name}
    </CardTitle>
    {this.props.report.description && 
    <CardSubtitle
      className="mb-2 text-muted"
      tag="h6"
    >
      {this.props.report.description}
    </CardSubtitle>}
  </CardBody>
  <img
    alt="Card cap"
    src={this.props.report.image}
    width="100%"
  />
  <CardBody>
    <CardText>
    {this.props.report.summary}

    </CardText>
    <CardLink href="#">
      See More
    </CardLink>

  </CardBody>
</Card>
  );
}
}

export default ReportCard;