import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Images
import feature1 from "../../assets/images/features/img-1.png";
import feature2 from "../../assets/images/features/img-2.png";
import bigLogoPic from "../../assets/images/logo-positive-vertical.jpg";
import plainHeader from "../../assets/images/headerImage.jpg";
import { airData } from '../../datafeed/sitedata';

import bg1 from "../../assets/images/headerWithText.jpg";

class BigLogo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            smallFeatures1 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ],
            smallFeatures2 : [
                { title : "Donec quam felis" },
                { title : "Ultricies nec" },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>


        <section className="sectionSmallMargin"  id="home">

            <Container  className="main-logo-container" >
                <Row>
                    <Col lg={12} sm={12}>

                    
                    <div className="box-shadow" style={{paddingRight: "4em", paddingLeft: "5em"}}>
                                    <img src={bigLogoPic} alt="" className="img-fluid mx-auto d-block"/>
                                </div>
                    </Col>
                </Row>

                <Row className="justify-content-center"  style={{marginTop: "5em"}} >
                            <Col lg={8}>
                
                                <div className="text-center mb-4  alignTop">
                                    <h3 className="text-black mb-4">{airData.headline}</h3>
                                </div>
    
                            </Col>
                        </Row>

</Container>

  
       
            
        </section>

        <div className="box-shadow wide-image">
                <img src={plainHeader} alt="" className="img-fluid mx-auto d-block"/>
            </div>



            </React.Fragment>
        );
    }
}

export default BigLogo;