
import laptop from "../assets/images/laptop.png";
import APACLaptop from "../assets/images/APACLaptop.png";
import NorthAmericaLaptop from "../assets/images/NorthAmericaLaptop.png";
import LatinAmericaLaptop from "../assets/images/LatinAmericaLaptop.png";

const airData = {
    intro: "Discover AIR Control Tower",
    headline: "Insight and analysis leadership for the global airline industry",
    subHeadline: "Independence, integrity, objectivity, and originality",
    missionHeading: `AIR Control Tower's mission`,
    mission: "AIRCT is an independent platform with a mission to provide impactful research and analysis via its unique global model and deep expertise to forecast earnings, cash flows, de-carbonisation financing prospects, and related scenarios across the global airline industry and aviation value chain.",
tagLine: `Independence, integrity, objectivity, and originality  of our extensive analysis to get to to the "engine room" of each company in a world of superficial perspectives.`,
experienceHeading: `Trusted experience and perspective`,
experienceText: `Our analysis, focused on our 2 "I"s and "O"s principles, is used to dynamically assess the health of the global industry and individual companies within it, predict potential surprises and identify resultant second derivative effects. Insights are traditionally used across the aviation value chain, including institutional investors, private equity, aircraft lessors, travel technology firms, airports, consultants and airline industry management around the world.`,
services : [
    { icon : "award", title : "Independence", desc : "Tackling the most difficult topics often under-analysed, we embrace the thorniest of issues to prioritise gaining real insight to aid decision making. This results in research challenging strategies and perceptions. Presently, this includes a firm focus on funding de-carbonisation, with many airlines planning to reduce emissions but not yet able to fully demonstrate how. " },
    { icon : "user-check", title : "Integrity", desc : "Our key objectives include providing real insight by digging deeper and considering the full spectrum of risks and second derivative effects. Our detailed approach is designed to answer big questions and validate and quantify theories, providing maximum accountability. " },
    { icon : "search", title : "Objectivity", desc : "Our approach to providing insight to aid clients includes a range of tools to assess risks, test theories and avoid common positive or negative biases in research, acknowledging forecasting challenges in a volatile environment and the resultant need to clearly understand key sensitivities. While we provide quarterly and annual, short and long term forecasts and perspectives on financial strategy, we strive to avoid narrow over-emphasis on one viewpoint for a company/market to the detriment of research quality.   " },
    { icon : "zap", title : "Originality", desc : "We want to make a difference. We present a unique perspective drawing on nearly 20 years of experience analysing the economics of every link in the supply chain, and an extensive industry network. We focus on under-appreciated drivers of strategic decision making and financial performance, with a view to understanding potential outcomes at the earliest stage possible, and maximising the impact of our research.  " },
],
testimonials: [
    { name: "Ana de Pro Gonzalo", bio: "Board Member, Novartis, ST Microelectronics, National Express, Merlin Properties Socimi SA. Ex-CFO Amadeus 2010-2020.", desc: "Neil is an experienced and very shrewd analyst on the global airline industry. He has an unswerving capacity to see facts from multiple points of view, remaining always open-minded and objective. The questions that he asks demonstrate real understanding and insight on the complexities of the industry and the wider macro context." },
    { name: "Carolyn McCall, DBE", bio: "CEO, ITV. Ex-CEO, easyJet (2010-2017), Guardian Media Group.", desc: "Neil has a track record of tackling big themes and their ramifications for the industry, and its competitive landscape. His approach of deeply analysing company performance and challenging strategies, while ensuring consideration of perspectives from both sides, has driven valued dialogue with management teams." },
    { name: "Marie Owens Thomsen", bio: "Chief Economist, IATA. Ex-Chief Economist, HSBC, Merrill Lynch, Indosuez.", desc: "I wish to express my personal appreciation of Neil and the pertinent and deep analysis that he and AIR Control Tower provide of and for our industry. Neil has long been a trusted partner of IATA’s and we look forward to staying close also in the future through Neil’s new endeavour."  },
    { name: "Stephen Kavanagh", bio: "Board Member, Aer Lingus, CDB Aviation. Ex-CEO Aer Lingus 2015-2018.", desc: "Neil has a great ability to identify the real value drivers in his airline research and provide real insight. His analysis is informed by a comprehensive understanding of the industry, its competitive dynamic, and through the strong relationships built with many of its key players." },
    { name: "Ulrik Svensson", bio: "Ex-CFO Lufthansa Group (2017-2020), CEO, Melker Schoerling AB, CFO, Esselte AB.", desc: "Neil’s experience and deep industry knowledge mean he is very well positioned to understand and debate financial strategy and competitive positioning with airline management teams." },
],
navItems:                 [{ id: 1, idnm: "home", navheading: "Home" },
{ id: 2, idnm: "about", navheading: "About Us" },
{ id: 3, idnm: "research", navheading: "Research & Analysis" },
{ id: 4, idnm: "clients", navheading: "Clients" }],




research: {
    subtitle: "Employment of extensive experience, market knowledge and network to produce detailed research, fundamental strategic analysis, financial models, and unique, dynamic forward-looking analysis tools on an airline by airline and market by market basis covering the global industry:"
},
about: {
    line1bold: "AIR Control Tower",
    line1normal: " is an independent research and analysis platform.  Its mission is to provide impactful insight and analysis across the global airline industry at a company and market level via detailed financial models to aid decision making for key stake holders at a time when risks have seldom, if ever, been higher and the global sector grapples with the imperative of funding de-carbonisation.  Unique analysis forecasting global carrier-level earnings/cash flows, and restructuring/de-leveraging prospects, is designed to dynamically assess the health of the industry, predict surprises and identify potential requirements for third party support.",
    line2bold: "Neil Glynn, FCA, CFA, is Founder and Managing Director of AIR Control Tower.  He is currently also a mentor to IATA's Accelerate@IATA program, powered by Plug & Play.  ",
    line2normal: "Neil has near to 20 years of aviation experience, based in Dublin and London, including the roles of Credit Officer at CIT Aerospace and head of global transport equity research at Credit Suisse.  The quality of Neil’s industry-leading research and forecasting across the value chain is long-recognized by investors (long track record of top tier placing in Institutional investor European Transport analyst survey; ranked #3 in last survey in 2021) and corporates (regular management team/board workshops, conference presentations around the world).  Neil has also led 10 initial public offering (IPO) processes, assessing the investment case of corporates seeking to attract new investors."
},
reports: {
    heading: "In-depth research and analysis uniquely covering every major regional market at a company-level and regional-level, with a key objective of maximum research quality to elevate insight. "
},
reportTypes: [{
name: "Europe", description: undefined, summary: 'Race to recover margins and balance sheets as M&A returns as a major theme. ', image: laptop
},


{
    name: "North America", description: undefined, summary: 'Market structure best positioned to cope with strong inflationary headwinds. ', image: NorthAmericaLaptop
    },

    {
        name: "Latin America", description: undefined, summary: 'Emergence from Chapter 11 processes and low cost carrier market share ambitions dominate the landscape. ', image: LatinAmericaLaptop
        },

        {
            name: "Asia Pacific", description: undefined, summary: 'Difficult to cover capital costs pre-pandemic. Will the post-pandemic era prove any different?', image: APACLaptop
            },

           
]

}

export { airData }