import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";
import ServiceBox from "./services-box";
import { airData } from '../../datafeed/sitedata';

class Process extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services : airData.services
        }
    }
    
    render() {
        return (
            <React.Fragment>
        <section className="section bg-light" id="services">
            <Container>
            <SectionTitle
            title="Our Values"
    
            />

                <Row>
                    <ServiceBox services={this.state.services} />
                </Row>
                
{false && (
                <Row className="mt-4">
                    <Col lg={12}>
                        <div className="text-center">
                            <Link to="#" className="btn btn-success">View more</Link>
                        </div>
                    </Col>
                </Row>)}
                
            </Container>
            
        </section>
            </React.Fragment>
        );
    }
}

export default Process;