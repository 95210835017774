import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import { airData } from '../../datafeed/sitedata';
import ReportCard from './reportCard';

class ReportCardList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports : airData.reportTypes,
            headings: airData.reports
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="reportList">
                    <Container  style={{paddingLeft: "3em", paddingRight: "3em"}}>

                        
            <SectionTitle
                subtitle="Our reports"
                
            />   

            <h4>{this.state.headings.heading}</h4>
            <p></p>
            <p>&nbsp;</p>

                        <Row>

                            {this.state.reports.map((x) => 
                            <Col className="ml-lg-auto" sm="6" lg="6" xs="12">
                            <ReportCard report={x} />
                        </Col>
                            )}

                        </Row>
                    
                    </Container>
                    
                </section>
            </React.Fragment>
        );
    }
}

export default ReportCardList;